<template>
  <div>
    <Hero />
    <Highlight1 />
    <Highlight2 />
    <Highlight3 />
    <BlockchainHighlight />
    <Footer :disclaimerContent="3" />
  </div>
</template>

<script>
import Hero from "@/components/home/Hero";
import Highlight1 from "@/components/home/Highlight1";
import Highlight2 from "@/components/home/Highlight2";
import Highlight3 from "@/components/home/Highlight3";
import BlockchainHighlight from "@/components/home/BlockchainHighlight";
import Footer from "@/components/partials/Footer";
import { installHotJar } from "../utils/helpers";

export default {
	name: "Home",
	components: {
		Hero,
		Highlight1,
		Highlight2,
		Highlight3,
		BlockchainHighlight,
		Footer,
	},
	data() {
		return {
			headParams: {
				title: this.$t('meta.HOME_TITLE'),
				description: this.$t('meta.HOME_DESC'),
			},
		};
	},
	mounted() {
		// $crisp.push(["do", "chat:hide"]);
		document.dispatchEvent(new Event("x-app-rendered"));
		installHotJar('')
	},
	head() {
		return {
			title: {
				inner: " ",
				separator: " ",
				complement: this.headParams.title,
			},
			meta: [
				{
					name: "description",
					content: this.headParams.description,
					id: "desc",
				},
				{ property: "og:type", content: "website", id: 'og-type'},
				{ property: "og:title", content: this.headParams.title, id: 'og-title' },
				{ property: "og:description", content: this.headParams.description, id: 'og-description' },
				{
					property: "og:image",
					content: "https://www.morpher.com/img/og_homepage_fb.png",
					id: 'og-image'
				},
				{
					property: "og:url",
					content: "https://www.morpher.com" + this.$route.path,
					id: 'og-url'
				},
				{ property: "twitter:card", content: "summary_large_image", id: 'twitter-card' },
				{ property: "twitter:site", content: "@morpher_io", id: 'twitter-site' },
				{
					property: "twitter:image",
					content: "https://www.morpher.com/img/og_homepage_tw.png",
					id: 'twitter-image'
				},
			],
			link: [
				{
					rel: "canonical",
					href: "https://www.morpher.com",
					id: "canonical",
				},
			],
		};
	},
};
</script>
